@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700&display=swap');

.theme-light {
  --primary: #3867d6;
  --secondary: #4478ff;
  --success: #27b582;
  --info: #45aaf2;
  --warning: #fe7a00;
  --error: #be1f45;
  --text: #201e1f;
  --neutral-000: #ffffff;
  --neutral-040: rgba(236, 240, 241, 0.4);
  --neutral-100: #ecf0f1;
  --neutral-200: #d8dcdd;
  --neutral-300: #cacdce;
  --neutral-400: #bcc0c0;
  --neutral-500: #afb2b2;
  --neutral-600: #a1a3a4;
  --neutral-700: #7e8081;
  --neutral-800: #5c5d5d;
  --neutral-900: #262626;

  --toastify-color-light: var(--neutral-000);
  --toastify-color-info: var(--info);
  --toastify-color-success: var(--success);
  --toastify-color-warning: var(--warning);
  --toastify-color-error: var(--error);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: var(--neutral-000);
  --toastify-toast-min-height: 4rem;
  --toastify-toast-max-height: 800px;

  --toastify-text-color-light: var(--text);
  --toastify-text-color-info: var(--neutral-000);
  --toastify-text-color-success: var(--neutral-000);
  --toastify-text-color-warning: var(--neutral-000);
  --toastify-text-color-error: var(--neutral-000);
}

.react-calendar {
  max-width: 100%;
  background: var(--neutral-000);
  line-height: 1.125em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  border-radius: 4px;
}

.react-calendar__navigation button:disabled {
  color: var(--neutral-200);
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--neutral-100);
}

.react-calendar__navigation__arrow {
  font-size: 24px;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

abbr:where([title]) {
  text-decoration: none !important;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: var(--text);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--neutral-200);
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid var(--neutral-100) !important;
}

.react-calendar__tile:disabled {
  background-color: var(--neutral-100);
  border-radius: 0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--neutral-100);
}

.react-calendar__tile--now {
  background: var(--primary) !important;
  color: var(--neutral-000);
  border: 1px solid var(--primary) !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--secondary) !important;
  border: 1px solid var(--secondary) !important;
}

.react-calendar__tile--hasActive {
  background: var(--primary);
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--secondary) !important;
}

.react-calendar__tile--active {
  background: var(--secondary) !important;
  color: var(--neutral-000) !important;
  border: 1px solid var(--secondary) !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--secondary) !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--neutral-100);
}
